import backofficeService from '../../api/backoffice'

export default {
  /**
   * Get list of categories
   * @param {*} params
   * @param {*} params.limit
   * @param {*} params.offset
   * @param {*} params.search
   * @param {*} params.local Add LocalData to categories
   */
  getAll(params) {
    return backofficeService.Api().get('expense', { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  /**
   * Get category by id
   * @param {*} params Query Options
   */
  getById(id, params) {
    return backofficeService.Api().get(`expense/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
  
  create(data) {
    return backofficeService.Api().post('expense', data)
      .then(response => {
        // // console.log('response', response)
        return response.data
      })
      .catch((error) => { throw error })
  },
  /**
   * Update specific category
   * @param {*} id
   * @param {*} data
   */
  update(id, data) {
    // console.log('data in update is :', data)
    return backofficeService.Api().put(`expense/${id}`, data)
      .then(response => response.data)
      .catch((error) => { throw error })
  },

  
  
  delete(id, params) {
    return backofficeService.Api().delete(`expense/${id}`, { params })
      .then(response => response.data)
      .catch((error) => { throw error })
  },
}
